import CardList, { CardTypes } from "../../../../../components/card/CardList";

const ReportsMainMenu = (obProps) => {

    const handleOnAdminActionClick = (item) => {
        obProps.onClick(item);
    };

    return (
        <>
            <div className="container  fw-bold text-left section">
                <div className="sectionTitle">REPORTS MAIN OPTIONS</div>
                <div className="sectionContent">
                    <CardList onClick={handleOnAdminActionClick} cardType={CardTypes.TINY} arCardList={obProps.arData} />
                </div>
            </div>
        </>
    );
};

export default ReportsMainMenu;