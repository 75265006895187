import "./ReportsSideMenu.scss";

const ReportsSideMenu = (obProps) => {

    return (
        <div className="container ReportsSideMenu">
            REPORTS SIDE OPTIONS
        </div>
    );
};

export default ReportsSideMenu;