import { Link } from "react-router-dom/cjs/react-router-dom.min";
import obConfig from "../../../../src/data/configuration.json";
import "./Footer.scss";

const Footer = (obProps) => {

	const viewsConfig = obConfig.VIEWS;

	return (
		<footer id="footer" className="footer-copyright  pt-0 pb-0">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div
							className="col-md-4 offset-md-1 footer-contact wow fadeInDown animated  p-1"
							style={{
								visibility: "visible",
								animationName: "fadeInDown",
							}}
						>
							<h6>Contact</h6>
							<p style={{ marginBottom: "0rem" }}>
								<i className="faf fa-map-marker-alt"></i>
								Kissimmee, FL - 34746 USA
							</p>
							<p style={{ marginBottom: "0rem" }}>
								<i className="faf fa-envelope"></i>
								Email:{" "}
								<a
									target="_blank"
									href="mailto:contact.mygreenmarket@gmail.com"
								>
									contact.mygreenmarket@gmail.com
								</a>
							</p>
						</div>

						<div
							className="col-md-4 footer-links wow fadeInUp animated  p-1"
							style={{
								visibility: "visible",
								animationName: "fadeInUp",
							}}
						>
							<div className="row">
								<div className="col">
									<h6>Links</h6>
								</div>
							</div>
							<div className="d-flex">
								<p className="Footer-Link">
									<Link className="nav-link" to="/">
										Home
									</Link>
								</p>
								{viewsConfig?.WHO_WE_ARE?.display && (
									<p className="Footer-Link">
										<Link className="nav-link" to="/whoweare">
											Our Team
										</Link>
									</p>
								)}
								{viewsConfig?.E_MANAGEMENT?.display && (
									<p className="Footer-Link">
										<Link className="nav-link" to="/emanagement">
											E-Management
										</Link>

									</p>
								)}
								{viewsConfig?.PREP_CENTER?.display && (
									<p className="Footer-Link">
										<Link className="nav-link" to="/prepfulfillment">
											Prep & Fulfillment
										</Link>
									</p>
								)}
								{viewsConfig?.PORTFOLIO?.display && (
									<p className="Footer-Link">
										<Link className="nav-link" to="/portfolio">
											Portfolio
										</Link>
									</p>
								)}
								{viewsConfig?.CONTACT?.display && (
									<p className="Footer-Link">
										<Link className="nav-link" to="/contact">
											Contact
										</Link>
									</p>
								)}
							</div>
						</div>

						<div
							className="col-md-3 footer-about wow fadeInUp animated p-1"
							style={{
								visibility: "visible",
								animationName: "fadeInUp",
							}}
						>
							<h6>Follow us on social media</h6>
							<div>
								<div className="container ">
									<div className="row p-1">
										<div
											className="col col-4 footer-social "
											style={{
												marginTop: "1rem",
												marginBottom: "1rem",
											}}
										>
											<a
												target="_blank"
												href="https://www.instagram.com/greenmarket_prepcenter/"
											>
												<i className="fab fa-instagram"></i>
											</a>
										</div>
										<div
											className="col footer-tm p-0"
											style={{ margin: "auto" }}
										>
											© MyGreenMarket
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
