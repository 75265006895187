import moment from 'moment';
import useAreaStateHook from "../hooks/useAreaStateHook";
import { AR_WEEK_DAYS } from '../../../../ReportConstants';

const useAmazonSalesHook = (obProps) => {

    const useAreaState = useAreaStateHook();


    const normalizeValue = saleValue => {
        return isNaN(saleValue) ? 0 : parseFloat(saleValue).toFixed(2);
    };


    const normalizeFBAFBM = (fbaFbm) => {
        switch (fbaFbm) {
            case "Amazon":
                return "FBA";
            default:
                return "FBM";
        }
    };

    const arWeekPrefixes = [1, 2, 3, 4, 5];

    const normalizeSales = (arSales) => {
        let arNewSales = arSales.map(thisSale => {
            if (!!thisSale["asin"]) {
                return {
                    ...thisSale,
                    "ship-state": useAreaState.normalizeSatate(thisSale["ship-state"]),
                    "fba-fbm": normalizeFBAFBM(thisSale["fulfillment-channel"]),
                    "item-price": normalizeValue(thisSale["item-price"]),
                    "purchaseDate": moment(thisSale["purchase-date"]).format("MM-DD @ HH:mm"),
                    "purchaseWeekDay": AR_WEEK_DAYS[moment(thisSale["purchase-date"]).day()],
                    "purchaseWeekNumber": `Week ${arWeekPrefixes[0 | moment(thisSale["purchase-date"]).date() / 7]}`,
                    "lastUpdatedDate": moment(thisSale["last-updated-date"]).format("MM-DD @ HH:mm"),
                    "item-status": thisSale["item-status"] === undefined ? "N/A" : thisSale["item-status"]
                }
            }
        });
        // console.log("arNewSales", arNewSales.length);
        const arNewSales2 = arNewSales.filter(Boolean);
        // console.log("arNewSales2", arNewSales2.length);
        return arNewSales2;
    };

    return {
        normalizeFBAFBM,
        normalizeSales
    }

};

export default useAmazonSalesHook;