import { Link } from "react-router-dom";
import "./Header.scss";
import "./HeaderAdmin.scss";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useRef } from "react";

const HeaderAdmin = (obProps) => {

	const obCurrentdUserData = useRef(JSON.parse(localStorage.getItem("mgm_currentData")));
	// console.log("HeaderAdmin - obCurrentdUserData", obCurrentdUserData.current);

	const handleClickOn_Logout = _ => {
		obProps.onLogout();
	}

	return (
		<header className="secure fixed-top">
			<nav className="navbar navbar-expand-md navbar-dark pt-0 pb-0 ">
				<Link className="logo navbar-brand" to="/admin">
					<img src="/img/logos/GM-New_Horizontal-DARK.fw.png" alt="GM Logo" />
				</Link>

				<button
					className="navbar-toggler d-lg-none"
					type="button"
					data-toggle="collapse"
					data-target="#navbarsExampleDefault"
					aria-controls="navbarsExampleDefault"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div
					className="collapse navbar-collapse"
					id="navbarsExampleDefault"
				>
					<ul className="navbar-nav ">
						{/* <li id="whoweareIcon" className="nav-item  asButton ">
							<Link className="nav-link " to="/whoweare">
								Clients
							</Link>
						</li>
						<li id="servicesIcon" className="nav-item asButton ">
							<Link className="nav-link " to="/services">
								Prep Center
							</Link>
						</li> */}

						<Dropdown className="navSecureUtilitiesLink">
							<Dropdown.Toggle variant="success" id="dropdown-utilities">
								Utilities
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Charts</Dropdown.Item>
								{/* <Dropdown.Item href="/secure/utilities/reports">Reports</Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown className="navSecureUtilitiesLink ">
							<div className="userData">
								<DropdownToggle variant="success" id="dropdown-user">
									<img alt="My Data" src="/img/header/iconUser.png" />
									<div className="user-name">{(obCurrentdUserData && obCurrentdUserData?.current) ? obCurrentdUserData.current?.user?.name : null}</div>
								</DropdownToggle>
							</div>

							<DropdownMenu>
								<DropdownItem href="/user/mydata">My Data</DropdownItem>
								<DropdownItem href="/user/preferences">Preferences</DropdownItem>
								<DropdownItem href="#" id="headerBtnLogout" onClick={handleClickOn_Logout}>Logout</DropdownItem>
							</DropdownMenu>
						</Dropdown>


					</ul>
				</div>
			</nav>
		</header>
	);
};

export default HeaderAdmin;
