import { data } from "jquery";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { AR_WEEK_DAYS, CHART_OPTIONS } from "../../../../../../ReportConstants";

const SalesChart_WeekNumber = (obProps) => {

    const [dataChart, setDataChart] = useState([
        ["-", "-"],
        ["-", 0],
        ["-", 0]
    ]);

    const optionsChart = {
        ...CHART_OPTIONS,
        title: "ORDERS PER WEEK IN THE MONTH",
        legend: { position: "none" },
    };

    const normalizeChartData = _ => {
        let arSales = obProps?.chartData?.all?.sales || [];
        if (arSales.length) {
            console.warn("normalizeChartData", arSales.length);

            const chartProps = ['purchaseWeekNumber'];

            const obCounts = arSales.reduce((r, e) => {
                Object.entries(e).forEach(([k, v]) => {
                    if (chartProps.includes(k)) {
                        if (!r[k]) r[k] = {}
                        r[k][v] = (r[k][v] || 0) + 1
                    }
                })
                return r;
            }, {})

            const obValues = obCounts[chartProps[0]];
            // obValues = obValues.sort((a, b) => a.key > b.key);
            let dataToChart = [
                ["WeekNumber", "Orders"]
            ];
            (["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]).forEach((thisWeekDay) => {
                if (obValues[thisWeekDay]) {
                    dataToChart.push([thisWeekDay, obValues[thisWeekDay]]);
                }
            });
            setDataChart(dataToChart);

        }
    };

    useEffect(() => {
        normalizeChartData();
    }, [obProps.chartData?.all?.sales]);
    console.log(">>>>>> ", obProps.chartData);

    return (
        <div className="">
            <Chart
                chartType="ColumnChart"
                width="100%"
                height="300px"
                data={dataChart}
                options={optionsChart}
            />
        </div>
    );

};

export default SalesChart_WeekNumber;