import axios from "axios";
import "./Login.scss";
import { useEffect, useState } from "react";

const Login = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "LOGIN",
			subHeaderText: "Login or Register",
			class: "gc_class_login",
			headerType: "normal"
		});
	});

	const [loginEmail, setLoginEmail] = useState('');
	const [loginPassword, setLoginPassword] = useState('');


	const handleLoginClick = (e) => {
		e.preventDefault();
		console.log("Login Click");
		const payload = { "email": loginEmail, "password": loginPassword };
		// const headers = { 'Content-Type': 'application/json' };

		// console.log(payload);

		axios
			.post("/api/login", payload) //, headers)
			.then(response => {
				if (response.status === 200) {
					localStorage.setItem("mgm_auth", response.headers["x-mgm_token"]);
					localStorage.setItem("mgm_currentData", JSON.stringify(response.data));

					if (response.data.user.role === 1) {
						window.location.href = "/admin";
					} else {
						window.location.href = "/secure";
					}

				}
			});

	};

	return (
		<div className="LOGIN">

			<form id={`loginForm`}>
				<div className="d-flex justify-content-center h-100 w-100 ">
					<div className="card w-100">
						<div className="card-header">
							<h4>Welcome</h4>
							{/* < div className="d-flex justify-content-end social_icon">
                                        <span><i className="fab fa-facebook-square"></i></span>
                                        <span><i className="fab fa-google-plus-square"></i></span>
                                        <span><i className="fab fa-twitter-square"></i></span>
                                    </div> */}
						</div>

						<div className="card-body">
							<div className="input-group flex-nowrap">
								<span className="input-group-text" id="addon-wrapping">
									<strong className="faf fa-user"></strong>
								</span>
								<input type="email"
									className="form-control" placeholder="e-mail" aria-label="Username" aria-describedby="addon-wrapping"
									onChange={(e) =>
										setLoginEmail(e.target.value)
									}
								/>
							</div>

							<div className="input-group form-group">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<strong className="faf fa-key"></strong>
									</span>
								</div>
								<input
									type="password"
									className="form-control"
									placeholder="password"
									name="user001_password"
									onChange={(e) =>
										setLoginPassword(e.target.value)
									}
								/>
							</div>
							<div className="row">
								<div className="form-check form-switch">
									<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
									<label className="form-check-label" for="flexSwitchCheckChecked">
										Remember me
									</label>
								</div>
							</div>
							<div className="form-group btnAction">
								<button type="button"
									className="btn btn-success w-75"
									onClick={handleLoginClick}>
									Login
								</button>
								{/* <input
									type="submit"
									value="Login"
									className="btn login_btn"
								/> */}
							</div>
						</div>
						<div className="card-footer">
							<div className="d-flex forgot_password justify-content-center">
								<a href="#">Forgot your password?</a>
							</div>
						</div>
					</div>
				</div>
			</form>

		</div>
	);
};

export default Login;
