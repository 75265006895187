import { useState } from "react";
import $ from "jquery";


const useLoadPage = (obProps) => {
    const [headerType, setHeaderType] = useState();

    const updateBodyClass = (className) => {
        let listClassesTemp = [];
        listClassesTemp.push(className);
        document.body.classList = listClassesTemp;
    };

    const handleMakeLinkActive = (linkId) => {
        $(`ul.navbar-nav li.nav-item`).removeClass("link_selected");
        $(`#${linkId}`).addClass("link_selected");
    };

    const updateDocument = (obPageProperties) => {
        if (obPageProperties) {
            document.title = "Green Market - " + obPageProperties.title;
            if (obPageProperties?.link_active) {
                handleMakeLinkActive(obPageProperties?.link_active);
            }
            setHeaderType(obPageProperties.headerType);
            updateBodyClass(obPageProperties.class);
        }
    };

    return { headerType, handleMakeLinkActive, updateDocument };
}

export default useLoadPage;