const SalesLoad_KeyDataContainer = (obProps) => {

    const sheetData = obProps.sheetData;
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div className="salesLoadExcel-keyDataContainer">
            <div className="row keyData">
                <div className="col col-3">
                    <div className=" property">
                        Orders Qtty
                    </div>
                    <div className=" value">
                        {sheetData?.all?.sales?.length}
                    </div>
                </div>
                <div className="col col-3">
                    <div className=" property">
                        Items Sold
                    </div>
                    <div className=" value">
                        {sheetData?.all?.quantity}
                    </div>
                </div>
                <div className="col col-3">
                    <div className=" property">
                        Total
                    </div>
                    <div className=" value text-primary">
                        {(sheetData?.all?.totalSale) ? USDollar.format(sheetData?.all?.totalSale) : USDollar.format(0.00)}
                    </div>
                </div><div className="col col-3">
                    <div className=" property ">
                        Total Cancelled
                    </div>
                    <div className=" value">
                        <span className="text-danger">{sheetData?.all?.totalCancelled}</span>
                        <span className="text-secondary font-weight-normal">{` ( ` + (sheetData?.all?.quantity * 0.01 * sheetData?.all?.totalCancelled).toFixed(2) + `% )`}</span>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SalesLoad_KeyDataContainer;