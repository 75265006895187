

const useAreaStateHook = (obProps) => {

    const normalizeSatate = inState => {
        switch (inState.toUpperCase()) {
            case "TENNESSEE":
            case "TENNESSE":
            case "TENNESEE":
            case "TENNESE":
            case "TENESE":
            case "TN":
                return "TN";
            case "MASSACHUSETTS":
            case "MA":
                return "MA";
            case "FLORIDA":
            case "FL":
                return "FL";
            case "COLORADO":
            case "CO":
                return "CO";
            case "OHIO":
            case "OH":
                return "OH";
            case "NEW JERSEY":
            case "NJ":
                return "NJ";
            case "NEW YORK":
            case "NY":
                return "NJ";
            case "NEVADA":
            case "NE":
                return "NE";
            case "INDIANA":
            case "IN":
                return "IN";
            case "TEXAS":
            case "TX":
                return "TX";
            case "GEORGIA":
            case "GA":
                return "GA";
            case "ILLINOIS":
            case "IL":
                return "IL";
            case "IOWA":
            case "IA":
                return "IA";
            case "CALIFORNIA":
            case "CA":
                return "CA";
            default:
                return inState.toUpperCase();
        }
    }

    return {
        normalizeSatate
    }

};

export default useAreaStateHook;