import { useEffect } from "react";
import "./PrepFulfillment.scss";

const PrepFulfillment = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "PREP & FULFILLMENT",
			subHeaderText: "Prep & Fulfillment",
			class: "gc_class_services",
			link_active: "prepcenterLink",
			headerType: "normal"
		});
	}, [obProps]);

	return (
		<>
			<div className="row align-items-start">
				<p>
					Comprehensive Services:
					We offer a comprehensive suite of Amazon fulfillment services, including FBA prep, labeling, warehousing, order processing, and shipping. Our goal is to take the logistics burden off your shoulders, allowing you to focus on growing your Amazon business.
				</p>
				<p>
					Reliability and Efficiency:
					We measure our success by the reliability and efficiency of our Amazon fulfillment services. Timely and accurate order processing, seamless inventory management, and on-time shipping are our commitment to you.
				</p>
			</div>
		</>
	);
};

export default PrepFulfillment;
