// import { Link } from "react-router-dom";
import "./SubHeader.scss";

const SubHeader = (obProps) => {

	// const obConfig = obProps.obConfig;
	// console.log(obConfig);

	return (
		<div className="SubHeader">
			<div className="Infos">
				{/* {obConfig.LANGUAGES.show
					&& <div className="Languages" data-toggle="collapse" >
						<div className="text">Languages</div>
						<div className="flags">
							<Link to="/translate-US">
								<img src="/img/header/usa_icon.png" />
							</Link>
							<Link to="/translate-BR">
								<img src="/img/header/bra_icon.png" />
							</Link>
						</div>
					</div>
				} */}
				SubHeader options
				{/* <div className="Keyfactor Consulting">
					<div className="left">E-MGMNT</div>
					<div className="right">
						<div>
							<span>+5K</span>Products Sold
						</div>
						<div>
							<span>+$16k</span>Sales Generated
						</div>
					</div>
				</div>
				<div className="Keyfactor Fulfillment">
					<div className="left">PREP FULFILMENT</div>
					<div className="right">
						<div>
							<span>+1900</span> Units Shipped
						</div>
						<div>
							<span>100%</span> Quality
						</div>
					</div>
				</div> */}
			</div>

		</div >
	);
};

export default SubHeader;
