import { useEffect, useState } from "react";
import GCBreadCrumb from "../../../../components/GCBreadCrumb/GCBreadCrumb";
import ReactPDF, { Document, PDFViewer } from '@react-pdf/renderer';
import "./Reports.scss";
import ReportsSideMenu from "./Structure/ReportsSideMenu";
import SalesLoadExcel from "./ReportTypes/Sales/SalesLoadExcel";
import arReportTypes from "./Data/ReportTypes.json"
import ReportsMainMenu from "./Structure/ReportsMainMenu";

const Reports = (obProps) => {

    const thisPageData = {
        title: "Reports",
        subHeaderText: "Admin Reports",
        class: "gc_class_secure gcReports",
        link_active: "reportsHomeLink",
        headerType: "secure"
    }
    useEffect(_ => {
        obProps.onLoadPage(thisPageData);
    }, [thisPageData]);

    const [reportType, setReportType] = useState(null);


    const handleDataLoaded = (data) => {

    };

    const handleReportMainClicked = (item) => {
        setReportType(item?.link);
    };

    const handlePrintPDF = (obData) => {
        console.log(">>>> handlePrintPDF : ", obData);
        ReactPDF.renderToStream(<SalesLoadExcel />);
    };

    const handleBackToMain = _ => {
        setReportType(null);
    };

    const arReportTypesNormalized = arReportTypes.data.map(thisOption => {
        return {
            ...thisOption,
            image: `../../../img/adm/clientView/Option_${thisOption.abbr}.png`
        }
    });

    return (
        <>

            <div className="row">
                <div className="col col-sm-12 col-lg-1 reportLeftOptions">
                    <ReportsSideMenu />
                </div>
                <div className="col col-sm-12 col-lg-11">

                    <GCBreadCrumb thisPageName={thisPageData.title} level={3} />

                    {reportType === null && <ReportsMainMenu onClick={handleReportMainClicked} arData={arReportTypesNormalized} />}

                    {/* <PDFViewer className="PDF"> */}
                    {reportType === "SalesLoadExcel" &&
                        <SalesLoadExcel
                            // onLoadPage={obProps.onLoadPage}
                            onPrintPDF={handlePrintPDF}
                            onDataLoaded={handleDataLoaded}
                            onBackToMain={handleBackToMain}
                        />
                    }
                    {/* </PDFViewer> */}
                </div>
            </div>
        </>
    );
};

export default Reports;
