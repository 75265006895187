import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./ClientData.scss";
import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import GCBreadCrumb from '../../../../components/GCBreadCrumb/GCBreadCrumb';
import AdminSideBarMenu from '../../../../components/structure/sidebarMenu/AdminSideBarMenu';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useLoggedUser from '../../../hooks/useLoggedUser';

function ClientData({ onLoadPage }) {
    const { myCookie } = useLoggedUser();

    const thisPageData = {
        title: "ADMIN CLIENT DATA",
        name: `Client Data`,
        subHeaderText: "Admin Client Data",
        class: "gc_class_admin",
        headerType: "adm",
    };
    useEffect(_ => {
        onLoadPage(thisPageData);
    }, []);

    const history = useHistory();

    const obClientData = JSON.parse(localStorage.getItem("mgm_currentData"));
    const obClientInfo = obClientData.client.info;

    // const obClientFreshData = useQuery({
    //     queryKey: ['client-data'],
    //     refetchOnWindowFocus: false,
    //     queryFn: async () => axios.get("/api/client/1", {
    //         headers: { "x-mgm_token": myCookie }
    //     }),
    //     select: (response) => {
    //         if (response.status === 200) {
    //             console.log("Data status 200:", response.data);
    //             let arClientsData = response.data;
    //             // arClientsData.forEach(thisClient => {
    //             //     thisClient.linkId = thisClient.clientId;
    //             //     thisClient.image = `../img/clients/logos/LogoDashboard_${thisClient.clientId}.fw.png`
    //             // });
    //             return arClientsData;
    //         } else {
    //             console.warn(">> DEU MERDA ", response.status);
    //             return [];
    //         }
    //     }
    // }).data;

    const boShowAdminSideBarMenu = true;

    const handleSave_BasicData = () => {
        console.log(">> handleSave_BasicData");
    };

    return (
        <>

            <div className="Full-Width text-left clientData ">

                <div className="row flex-nowrap">
                    {boShowAdminSideBarMenu &&
                        (<div className="col-auto px-0 adminSideBarMenu-Container">
                            <AdminSideBarMenu />
                        </div>)
                    }

                    {/* <div className="col col-11 ps-md-2 text-left"> */}
                    <main id="ClientDashboardMain" className="col col-10 ps-md-2 text-left rightOfAadminSideBarMenu">
                        <GCBreadCrumb thisPageName={thisPageData.name} level={3} />

                        <Accordion defaultActiveKey="0" className='p-4 m-0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Basic Data</Accordion.Header>
                                <Accordion.Body>
                                    <Form>
                                        <div className="row">
                                            <div className='col-12 col-md-6'>
                                                <Form.Group className="mb-3" controlId="form.basicData.companyName">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Company Name"
                                                        defaultValue={obClientInfo?.name ?? ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.basicData.EIN">
                                                    <Form.Label>EIN Number</Form.Label>
                                                    <Form.Control type="text" placeholder="XX-XXXXXXX"
                                                        defaultValue={obClientData?.ein ?? ''}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <Form.Group className="mb-3" controlId="form.basicData.Email">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.basicData.ExtraInfo">
                                                    <Form.Label>Extra information</Form.Label>
                                                    <Form.Control as="textarea" rows={3} />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='buttons'>
                                            <Button variant="warning">Cancel</Button>{' '}
                                            <Button onClick={handleSave_BasicData} variant="success">Save</Button>{' '}
                                        </div>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Contacts Info</Accordion.Header>
                                <Accordion.Body>
                                    <Form>
                                        <div className="row">
                                            <div className='col-12 col-md-6'>
                                                <h6><b>MAIN CONTACT</b></h6>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.companyName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Main Contact Name"
                                                        defaultValue={obClientData?.contacts?.main?.name ?? ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.EIN">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control type="phone" placeholder="+X XXXXXXXXXX"
                                                        defaultValue={obClientData?.ein ?? ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.Email">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="City / UF / Country" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.location">
                                                    <Form.Label>Location</Form.Label>
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </Form.Group>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <h6><b>ADDITIONAL CONTACT(S)</b></h6>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.contacts.name">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Contact Name"
                                                        defaultValue={''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.Email">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="form.contactsInfo.ExtraInfo">
                                                    <Form.Label>Extra information</Form.Label>
                                                    <Form.Control as="textarea" rows={3} />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='buttons'>
                                            <Button variant="warning">Cancel</Button>{' '}
                                            <Button variant="success">Save</Button>{' '}
                                        </div>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Seller Account Info</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>FBA Configuration</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Agreement Info</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </main>
                </div>

                {/* </div> */}
            </div>
        </>
    );
}

export default ClientData;