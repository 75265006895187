import "./CardTiny.scss";
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

const CardTiny = (obProps) => {

  const handleOnClick = _ => {
    // console.log(obProps.cardData);
    obProps.onClick(obProps.cardData);
  }

  return (
    <Card className="CardTiny"
      style={{
        "color": "white",
        "backgroundImage": `url(${obProps.cardData.image})`
      }}
      onClick={handleOnClick}
    >
      <Card.Body>
        <Card.Title>{obProps.cardData.title}</Card.Title>
        {/* <Card.Body> */}
        {/* <Badge bg="warning">9</Badge> */}
        {/* </Card.Body> */}
      </Card.Body>
    </Card>
  );
};

export default CardTiny;