import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { CHART_OPTIONS } from "../../../../../../ReportConstants";

const SalesChart_Country = (obProps) => {

    var optionsChart = {
        ...CHART_OPTIONS,
        region: "US",
        displayMode: 'regions',
        resolution: 'provinces',
        title: "USA",
        is3D: false,
        legend: { position: "none" },
        datalessRegionColor: "transparent",
        colorAxis: { colors: ["#ebf5ed", "#139633"] },
        animation: {
            startup: true,
            easing: "in",
            duration: 2000,
        },
        tooltip: { ignoreBounds: true },
    };

    const [dataChart, setDataChart] = useState([]);

    const normalizeChartData = _ => {
        const arSales = obProps?.chartData?.all?.sales || [];

        if (arSales?.length) {
            const chartProps = ['ship-state'];

            const obCounts = arSales.reduce((r, e) => {
                Object.entries(e).forEach(([k, v]) => {
                    if (chartProps.includes(k)) {
                        if (!r[k]) r[k] = {}
                        r[k][v] = (r[k][v] || 0) + 1
                    }
                })
                return r;
            }, {})

            const obValues = obCounts[chartProps[0]];

            let dataToChart = [
                ["State", "Items Sold"]
            ];
            Object.keys(obValues).forEach(key => {
                dataToChart.push([key, obValues[key]]);
            });

            setDataChart(prev => dataToChart);
        }
    };


    useEffect(_ => {
        normalizeChartData();
    }, [obProps.chartData?.all?.sales]);

    return (
        <>
            <Chart
                chartType="GeoChart"
                width="100%"
                height="250px"
                data={dataChart}
                options={optionsChart}
            />
            <div id="SalesChart_Country"></div>
        </>

    )
};

export default SalesChart_Country;