import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-brands-svg-icons";
import "./AdminSideBarMenu.scss"

const AdminSideBarMenu = (obProps) => {

    return (
        <div id="sidebar" class="collapse collapse-horizontal show border-end">
            <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100">
                <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
                    <FontAwesomeIcon icon={Icons.faFacebook} />
                    <span>Home</span>
                </a>
                <div class="accordion" id="">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInventory" aria-expanded="true" aria-controls="collapseOne">
                                Inventory
                            </button>
                        </h2>
                        <div id="collapseInventory" class="accordion-collapse collapse " data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
                                    <FontAwesomeIcon icon={Icons.faTwitter} />
                                    <span>Inventory</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReports" aria-expanded="true" aria-controls="collapseOne">
                                Reports
                            </button>
                        </h2>
                        <div id="collapseReports" class="accordion-collapse collapse " data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
                                    <FontAwesomeIcon icon={Icons.faTwitter} />
                                    <span>Reports</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseShipments" aria-expanded="true" aria-controls="collapseOne">
                                Shipments
                            </button>
                        </h2>
                        <div id="collapseShipments" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
                                    <FontAwesomeIcon icon={Icons.faTwitter} />
                                    <span>Shipments</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvertising" aria-expanded="true" aria-controls="collapseOne">
                                Advertising
                            </button>
                        </h2>
                        <div id="collapseAdvertising" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
                                    <FontAwesomeIcon icon={Icons.faTwitter} />
                                    <span>Advertising</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div id="sidebar" class="collapse collapse-horizontal show border-end">
        //     <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100">
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <FontAwesomeIcon icon={Icons.faFacebook} />
        //             <span>Home</span>
        //         </a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <FontAwesomeIcon icon={Icons.faTwitter} />
        //             <span>Inventory</span>
        //         </a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <i class="bi bi-heart"></i> <span>Shipments</span>
        //         </a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <i class="bi bi-bricks"></i> <span>Reports</span>
        //         </a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <i class="bi bi-clock"></i> <span>Reviews</span>
        //         </a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
        //             <i class="bi bi-archive"></i> <span>Advertising</span>
        //         </a>
        //         {/*<a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><i class="bi bi-gear"></i> <span>Item</span></a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><i class="bi bi-calendar"></i> <span>Item</span></a>
        //         <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><i class="bi bi-envelope"></i> <span>Item</span></a> */}
        //     </div>
        // </div>
    );
};

export default AdminSideBarMenu;