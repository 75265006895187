import { useEffect, useState } from "react";
import "./AdminDashboardCharts.scss";
import { Chart } from "react-google-charts";
import { CHART_OPTIONS } from "../../../../../client/src/Routes/ReportConstants";


const AdminDashboardCharts = (obProps) => {

    const options = {
        title: "Sales during the Month",
        hAxis: { title: "Month Days", viewWindow: { min: 0, max: 31 } },
        vAxis: { title: "Qtty", viewWindow: { min: 0, max: 50 } },
        legend: "none",
        backgroundColor: 'transparent',
        animation: {
            startup: true,
            easing: "linear",
            duration: 800,
        },
    };

    const data = [
        ["Month Days", "Qtty"],
        [18, 12],
        [4, 5],
        [11, 14],
        [20, 5],
        [28, 10],
        [6, 37],
        [14, 25],
        [3, 16],
        [16, 21],
        [23, 16],
        [30, 21]
    ];


    const data2 = [
        ["x", "FBA", "FBM"],
        [0, 0, 0],
        [1, 10, 5],
        [2, 23, 15],
        [3, 17, 9],
        [4, 18, 10],
        [5, 9, 5],
        [6, 11, 3],
        [7, 27, 19],
    ];

    const options2 = {
        title: "Inventory Level",
        hAxis: {
            title: "Month",
        },
        vAxis: {
            // title: "Inventory Level",
        },
        series: {
            1: { curveType: "function" },
        },
        backgroundColor: 'transparent',
        animation: {
            startup: true,
            easing: "linear",
            duration: 800,
        },
    };

    const data3 = [
        ["Task", "Hours per Day"],
        ["Prod A", 10],
        ["Prod B", 5],
        ["Prod C", 6],
        ["Prod D", 8],
        ["Prod E", 7], // CSS-style declaration
    ];

    const options3 = {
        ...CHART_OPTIONS,
        title: "Sales per Product",
        pieHole: 0.1,
        is3D: false,
        legend: { textStyle: { fontName: 'Calibri', fontSize: 10, bold: false } },
        backgroundColor: 'transparent',
        animation: {
            startup: true,
            easing: "in",
            duration: 800,
        },
    };

    const data4 = [
        ["Director (Year)", "FBM", "FBA"],
        ["JAN - MAR", 8, 14],
        ["APR - JUN", 15, 16],
        ["JUL - SET", 8, 26],
        ["OUT - DEZ", 10, 20],
    ];

    const options4 = {
        title: "Sales Channel",
        vAxis: { title: "" },
        isStacked: true,
        legend: { textStyle: { fontName: 'Calibri', fontSize: 9, bold: false } },
        backgroundColor: 'transparent',
        animation: {
            startup: true,
            easing: "linear",
            duration: 800,
        },
    };

    return (
        <div className="Full-Width fw-bold text-left section dashboard-Charts">
            <div className="sectionTitle">DASHBOARD CHARTS</div>
            <div className="sectionContent">
                <div className=" row chartsLine">
                    {/* <div className="charts4Line chart1">
                        <Chart
                            chartType="ScatterChart"
                            data={data}
                            options={options}
                            width="100%"
                            height="250px"
                            legendToggle
                        />
                    </div>
                    
                    <div className="charts4Line chart3">
                        <Chart
                            chartType="SteppedAreaChart"
                            width="100%"
                            height="250px"
                            data={data4}
                            options={options4}
                            legendToggle
                        />
                    </div> */}
                    <div className=" chart2">
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="250px"
                            data={data3}
                            options={options3}
                        />
                    </div>
                    {/* <div className="chart4">

                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="250px"
                            data={data2}
                            options={options2}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default AdminDashboardCharts;