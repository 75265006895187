import React, { useEffect, useState } from "react";
import "./Secure.scss";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useLoggedUser from "../hooks/useLoggedUser";
import useLocalStorage from "../hooks/useLocalStorage";
import useDBClient from "./_hooks/useDBClient";


const Secure = (obProps) => {

    const { myToken, obClientData } = useLoggedUser();
    const { getLocalStorageData, setLocalStorageData } = useLocalStorage();
    const { loadDBClient } = useDBClient(myToken);
    let obClientDataTEMP = {};

    useEffect(_ => {
        var obStorageData = getLocalStorageData();
        if (!obStorageData.client?.info?.clientId) {
            // console.warn("No Client Data");
            const fnGetDBClient = async _ => {
                const obClientInfo = await loadDBClient();
                // console.log("Client Dashboard obClientInfo", obClientInfo.data);
                obStorageData.client.info = obClientInfo.data;
                setLocalStorageData(obStorageData);
                // console.warn(obStorageData);
                obClientDataTEMP = obStorageData;
            }
            fnGetDBClient();
        } else {
            obClientDataTEMP = obStorageData;
        }
    }, []);



    const [clientName, setClientName] = useState();
    const [clientLogo, setClientLogo] = useState();
    useEffect(_ => {
        setClientName(obClientDataTEMP?.client?.info?.name);
        setClientLogo(`../../../../img/clients/logos/LogoDashboard_${obClientDataTEMP?.client?.info?.clientId}.fw.png`);
    }, [obClientData])


    return (
        <div className="content SECURE_MAIN container-fluid Full-Width ">
            <div className="containerBackground containerAdminBackground">
                CLIENT DASHBOARD
            </div>
            <div className="containerBackground containerClientBackground">
                {clientName}
            </div>
            <div className="containerBackground containerClientBackgroundImg"
                style={{
                    "backgroundImage": `url(${clientLogo})`
                }}
            >
            </div>
            {React.cloneElement(obProps.children,
                { onLoadView: obProps.updateDocument, obClientData: obClientDataTEMP }
            )}
        </div>
    );
};

export default Secure;