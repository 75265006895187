import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import "./ClientDashboard.scss";
import ClientView_Data from "./data/ClientView_Data.json";
import CardList, { CardTypes } from "../../../../components/card/CardList";
import GCBreadCrumb from "../../../../components/GCBreadCrumb/GCBreadCrumb";
import DashboardCharts from "../Charts/DashboardCharts";
import AdminSideBarMenu from "../../../../components/structure/sidebarMenu/AdminSideBarMenu";

const ClientDashboard = (obProps) => {
	const thisPageData = {
		title: "ADMIN CLIENT DATA",
		subHeaderText: "Admin Client Data",
		class: "gc_class_admin",
		headerType: "adm",
	}
	useEffect(_ => {
		obProps.onLoadPage(thisPageData);
		// console.warn(obProps);
	}, []);

	const history = useHistory();
	const boShowAdminSideBarMenu = true;

	const obClientData = JSON.parse(localStorage.getItem("mgm_currentData"));
	const obClientInfo = obClientData.client.info;
	// console.warn({ obClientData }, { obClientInfo });

	//---------- VIEW OPTIONS
	const arAvailableOptions = ["client", "ebay", "amazon"];
	const [arViewOptions_Client, setViewOptions_Client] = useState([]);
	const [arViewOptions_Amazon, setViewOptions_Amazon] = useState([]);
	const [arViewOptions_EBAY, setViewOptions_EBAY] = useState([]);

	const loadViewOptions = _ => {
		arAvailableOptions.forEach((thisMainOption) => {
			let arOptionItems = (ClientView_Data[thisMainOption])
				.sort((firstItem, secondItem) => firstItem.name - secondItem.name)
				.map(thisOption => {
					return {
						...thisOption,
						marketplace: thisMainOption,
						linkId: thisOption.abbr,
						image: `../../../img/adm/clientView/Option_${thisOption.abbr}.png`
					}
				});
			switch (thisMainOption) {
				case "client":
					setViewOptions_Client(arOptionItems);
					break;
				case "amazon":
					setViewOptions_Amazon(arOptionItems);
					break;
				case "ebay":
					setViewOptions_EBAY(arOptionItems);
					break;
				default:
					break;
			}
		});

	};
	const handleGeneralOptionsOnClick = (thisOption) => {
		switch (thisOption.abbr) {
			case "cdata":
				history.push(`/secure/client/view/clientData`);
				break;
			case "prepc":
				history.push(`/secure/client/prepCenter`);
				break;
			case "mktplc":
				history.push(`/secure/client/marketPlaces`);
				break;
			case "reprts":
				history.push(`/secure/client/reports`);
				break;

			default:
				break;
		}
	}
	const handleMarketPlaceOptionsOnClick = (thisOption) => {
		console.warn("handleMarketPlaceOptionsOnClick", thisOption);
		history.push(`/secure/client/mktplace/${thisOption.marketplace}/${thisOption.abbr}`);
	}
	useEffect(() => {
		loadViewOptions();
	}, []);

	return (
		<div className="container-fluid">

			<div className="row flex-nowrap">

				{boShowAdminSideBarMenu &&
					(<div className="col-auto px-0 adminSideBarMenu-Container">
						<AdminSideBarMenu />
					</div>)
				}

				<main id="ClientDashboardMain" className="col col-10 ps-md-2 text-left rightOfAadminSideBarMenu">
					<GCBreadCrumb thisPageName={`Client Dashboard`} level={2} />

					<div className=" Full-Width AdminContentAlternate">
						<div className="row p-0 m-0">
							<div className="col-4 ">
								<div className="section">
									<div className="sectionTitle">KEY FACTORS</div>
									<div className="sectionContent">
										<div className="Content">
											AA
										</div>
									</div>
								</div>
							</div>
							<div className="col-8">
								<DashboardCharts />
							</div>
						</div>

						<div className="Full-Width fw-bold text-left section ">
							<div className="row p-0 m-0">
								<div className="col-12 p-0">
									<div className="sectionTitle">GENERAL INFO</div>
									<div className="sectionContent">
										<div className="Content">
											<CardList
												onClick={handleGeneralOptionsOnClick}
												cardType={CardTypes.TINY}
												arCardList={arViewOptions_Client}

											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="Full-Width fw-bold text-left section ">
							<div className="row p-0 m-0">
								<div className="col-12 p-0">
									<div className="sectionTitle">Linked Market Places</div>
									<div className="sectionContent">
										<div className="linkedStores">
											{(arViewOptions_Amazon.length > 0) && (
												<div className="Full-Width fw-bold text-left section  pt-0">
													<div className="sectionTitle">AMAZON</div>
													<CardList
														onClick={handleMarketPlaceOptionsOnClick}
														cardType={CardTypes.TINY}
														arCardList={arViewOptions_Amazon}
													/>
												</div>
											)}
											{(arViewOptions_EBAY.length > 0) && (
												<div className="Full-Width fw-bold text-left section  pt-0">
													<div className="sectionTitle">E-BAY</div>
													<CardList
														onClick={handleMarketPlaceOptionsOnClick}
														cardType={CardTypes.TINY}
														arCardList={arViewOptions_EBAY}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div >
					</div>
				</main>
			</div>
		</div>
	);
};

export default ClientDashboard;
