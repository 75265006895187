
import React, { useEffect } from "react";
import "./MrktPlcCatalog.scss";
import GCBreadCrumb from "../../../components/GCBreadCrumb/GCBreadCrumb";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const MrktPlcCatalog = (obProps) => {
    const thisPageData = {
        title: "MarketPlace Catalog",
        subHeaderText: "MarketPlace - Catalog",
        class: "gc_class_secure",
        link_active: "marketPlaceCatalogHomeLink",
        headerType: "secure"
    }
    useEffect(_ => {
        obProps.onLoadPage(thisPageData);
    }, [obProps]);

    const { marketPlaceId } = useParams();

    return (
        <>
            <GCBreadCrumb thisPageName={thisPageData.title} level={3} />
            {thisPageData.title} - {marketPlaceId}
        </>
    );
};

export default MrktPlcCatalog;