import axios from "axios";

const useLogOut = (obProps) => {

    const handleLogOut = (pgFrom) => {
        console.log("LOGOUT APP, from:", pgFrom);

        const executeLogout = _ => {
            localStorage.removeItem("mgm_auth");
            localStorage.removeItem("mgm_currentData");
            window.location.href = "/";
        };

        const myCookie = localStorage.getItem("mgm_auth");
        if (myCookie) {
            axios.delete("/api/logout", {
                headers: { "x-mgm_token": myCookie }
            })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 204) {
                        executeLogout();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            executeLogout();
        }

    };


    return { handleLogOut };
}

export default useLogOut;