import "./KeywordHelper.scss";

const KeywordHelper = (obProps) => {
	obProps.onLoadPage({
		title: "ADMIN - Keyword Helper",
		subHeaderText: "Admin Keyword Helper",
		class: "gc_class_admin",
		headerType: "a",
	});

	return (
		<div className="content HOME ADMIN container-fluid Full-Width ">
			<div className="Full-Width ">
				<div className="Full-Width p-3 fw-bold">KEYWORD HELPER</div>

				<div className="Full-Width "></div>
			</div>
		</div>
	);
};

export default KeywordHelper;
