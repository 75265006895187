import { ReactGoogleChartEvent, Chart } from "react-google-charts";
import { CHART_OPTIONS } from "../../../ReportConstants";
import "./ChartCalendar.scss";
import moment from 'moment';
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

const ChartCalendar = (obProps) => {

    // console.log("ChartCalendar Data:", obProps);
    // const MySwal = withReactContent(Swal);

    const chartOptions = {
        ...CHART_OPTIONS,
        monthLabel: {
            fontName: 'Calibri',
            fontSize: 8,
            color: '#22bb22',
            bold: true,
            italic: true
        },
        monthOutlineColor: {
            stroke: '#981b48',
            strokeOpacity: 0.8,
            strokeWidth: 2
        },
        unusedMonthOutlineColor: {
            stroke: '#bc5679',
            strokeOpacity: 0.8,
            strokeWidth: 1
        },
        // underMonthSpace: 14,
        legend: { position: "none" },
        calendar: { cellSize: 14 },
        colorAxis: { minValue: 1, colors: ['#FF0000', '#00FF00', '#0000FF'] }
    };
    const chartEvents = [
        {
            eventName: "select",
            callback({ chartWrapper }) {
                // console.log("Selected ", chartWrapper.getChart().getSelection());
                const ddate = chartWrapper.getChart().getSelection()[0];
                const dateNormalized = moment(ddate.date).format("YYYY-MM-DD");
                Swal.fire({
                    title: 'Event from day ' + dateNormalized,
                    confirmButtonColor: '#097969',
                    html: "<span>This event is very important</span><br><br><span style='color: navy'>Clients Participating:</span><br>A<br>B",
                    width: 600,
                    showClass: {
                        popup: 'animated fadeInDown '
                    }
                });
            }
        }
    ];
    // const chartEvents = ReactGoogleChartEvent[] = [
    //     {
    //         eventName: "select",
    //         callback: ({ chartWrapper }) => {
    //             const chart = chartWrapper.getChart();
    //             const selection = chart.getSelection();
    //             if (selection.length === 1) {
    //                 const [selectedItem] = selection;
    //                 const dataTable = chartWrapper.getDataTable();
    //                 const { row, column } = selectedItem;

    //                 console.log("You selected:", {
    //                     row,
    //                     column,
    //                     value: dataTable?.getValue(row, column),
    //                 });
    //             }
    //         },
    //     },
    // ];

    const chartData = [
        [
            {
                type: "date",
                id: "Date",
            },
            {
                type: "number",
                id: "Holiday Level",
            },
        ],
        [new Date(2024, 1, 1), 1],
        [new Date(2024, 9, 4), 2],
        [new Date(2024, 9, 5), 2],
        [new Date(2024, 9, 12), 2],
        [new Date(2024, 9, 13), 2],
        [new Date(2024, 9, 19), 2],
        [new Date(2024, 9, 23), 2],
        [new Date(2024, 9, 24), 2],
        [new Date(2024, 9, 30), 2],
        [new Date(2025, 1, 1), 1],
        [new Date(2025, 1, 6), 3],
        [new Date(2025, 1, 7), 3],
        [new Date(2025, 1, 15), 2],
        [new Date(2025, 2, 2), 2],
    ];


    return (
        <div className="ChartCalendar container">
            <Chart
                chartType="Calendar"
                width="100%"
                height="30rem"
                data={chartData}
                options={chartOptions}
                chartEvents={chartEvents}
            />
        </div>
    );
};

export default ChartCalendar;

