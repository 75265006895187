import { useEffect } from "react";
import CardList from "../../../components/card/CardList";
import "./SecureHome.scss";

const SecureHome = (obProps) => {

    useEffect(_ => {
        obProps.onLoadPage({
            title: "CLIENT HOME",
            subHeaderText: "Client Home",
            class: "gc_class_secure",
            link_active: "clienthomeLink",
            headerType: "secure"
        });
    }, [obProps]);

    return (
        <div className="content HOME container-fluid Full-Width ">
            <div className="Full-Width ">

                <div className="Full-Width p-3 fw-bold">
                    SECURE LOGGED AREA
                </div>

                <div className="Full-Width ">
                    <CardList />
                </div>

            </div>
        </div>
    );
};

export default SecureHome;