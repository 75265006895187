
import "./CardList.scss";
import AdmCard from "./AdmCard";
import CardTiny from "./types/CardTiny";

export const CardTypes = {
    "TINY": "TINY",
    "SIMPLE": "SIMPLE",
    "MEDIUM": "MEDIUM",
    "COMPLETE": "COMPLETE"
};


const CardList = (obProps) => {
    const arCardList = (obProps.arCardList || []);

    // const handleCardClick = () =>{
    //     obProps.onClick(cardData);
    // }

    const loadList = () => {

        console.log("arCardList", arCardList);

        if (arCardList?.length) {

            return obProps.arCardList.map((thisItem) => {

                const cardData = {
                    title: thisItem.name,
                    ...thisItem
                };

                switch (obProps.cardType) {
                    case CardTypes.TINY:
                        return <CardTiny
                            onClick={obProps.onClick}
                            cardData={cardData}
                            key={thisItem.linkId}
                        />
                    default:
                        return <AdmCard />;
                }
            });

        }
    };

    return (
        <div className="CardList position-ref container-fluid Full-Width p-1 pb-2">
            {loadList()}
        </div>
    );
};

export default CardList;