import { useEffect } from "react";
import "./Contact.scss";

const Contact = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "CONTACT",
			subHeaderText: "Get in contact with us",
			class: "gc_class_contact",
			link_active: "contactLink",
			headerType: "normal"
		});
	}, [obProps]);

	return (
		<>
			<div className="CONTACT container alignCenter">

				<div className="row align-items-start alignCenter container">
					<div className="col-md-5 col-sm-12">
						<div className="card" >
							<h5 className="card-header">Our Locations</h5>
							<div className="row align-items-start alignCenter p-1 pb-0">
								<div className="col-md-12 col-sm-12">
									<img src="img/views/contact/usa_map_location.png" className="card-img-top map_location" alt="" />
								</div>
							</div>
							<div className="row align-items-start alignCenter">
								<div className="col-md-6 col-sm-12 ">
									<div className="card-body card_location">
										<h5 className="card-title">Head Office</h5>
										<div className="row align-items-start alignCenter">
											<div className="col-md-3 col-sm-3 pin_location ">
												<img src="img/views/contact/pin_red.fw.png" className="card-img-top " alt="" />
											</div>
											<div className="col-md-9 col-sm-9 text_location">
												<p className="card-text">
													<div>
														Kissimmee / FL
													</div>
													<div>
														34746
													</div>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="card-body card_location">
										<h5 className="card-title">Prep Center</h5>
										<div className="row align-items-start alignCenter">
											<div className="col-md-3 col-sm-3 pin_location ">
												<img src="img/views/contact/pin_blue.fw.png" className="card-img-top " alt="" />
											</div>
											<div className="col-md-9 col-sm-9 text_location">
												<p className="card-text">
													<div>
														Wimauma / FL
													</div>
													<div>
														33598
													</div>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-7 col-sm-12">
						<p>
							<form className="contact100-form validate-form" action="api/contact/send" method="post">
								<span className="contact100-form-title">
									Send us a message
								</span>
								<div className="row alignCenter d-flex p-3 jcCenter">
									<div className="alignCenter w-75 d-flex">
										Send us any questions you may have about our our processes and prices.<br></br>
										Once we receive your message, we will try to get back to you as quickly as possible.
									</div>
								</div>
								<div className="wrap-input100 validate-input" data-validate="Name is required">
									<input className="input100" type="text" value="" name="name" placeholder="Name" />
									<span className="focus-input100"></span>
									<span className="symbol-input100">
										<i className="fa fa-user" aria-hidden="true"></i>
									</span>
								</div>
								<div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
									<input className="input100" type="text" value="" name="email" placeholder="Email" />
									<span className="focus-input100"></span>
									<span className="symbol-input100">
										<i className="fa fa-envelope" aria-hidden="true"></i>
									</span>
								</div>
								<div className="wrap-input100 validate-input" data-validate="Message is required">
									<textarea className="input100" name="message" placeholder="Message"></textarea>
									<span className="focus-input100"></span>
								</div>
								<div className="container-contact100-form-btn">
									<button className="contact100-form-btn" >
										Send
									</button>
								</div>
							</form>

						</p>

					</div>
				</div>
			</div>



			{/* MODAL */}
			<div className="modal fade" id="modal_product_soon" tabIndex="-1" role="dialog" aria-labelledby="modal_product_soon" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modal_product_soon">Message Sent!</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body alignCenter">
							Thank you for your message.<br />
							We will try to answer it as soon as possible.
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;