import useLocalStorage from "../../Routes/hooks/useLocalStorage";
import "./GCBreadCrumb.scss";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import $ from "jquery";

const GCBreadCrumb = (obProps) => {

    const { getLocalStorageData } = useLocalStorage();
    const obUserData = getLocalStorageData();

    const isAdmin = obUserData.user.role === 1;
    const history = useHistory();

    const handleClickGoHome = _ => {
        (!isAdmin)
            ? history.push("/secure") : history.push("/admin");
    };
    const handleClickGoBack = _ => {
        history.goBack();
    };

    return (

        <div className="row Full-Width sideBarMenuButtonTop">
            <div className="col col-1 sideBarMenuButton">
                <a href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn btn-success"
                    onClick={() => {
                        $(".rightOfAadminSideBarMenu").toggleClass("col-10");
                        // $(".AdminContentAlternate").toggleClass("col-10");
                    }}
                >
                    <i class="bi bi-list bi-lg py-2 p-1"></i>{`< MENU`}
                </a>
            </div>
            <div className="col col-10">
                <div className="Full-Width fw-bold text-left GCBreadCrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href="" onClick={handleClickGoHome}>
                            {(isAdmin) ? `Admin` : ``} Home
                        </Breadcrumb.Item>
                        {obProps.level > 2 && (<Breadcrumb.Item onClick={handleClickGoBack}>{`Client Dashboard`}</Breadcrumb.Item>)}

                        <Breadcrumb.Item active>{obProps.thisPageName}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>

    );
};

export default GCBreadCrumb;