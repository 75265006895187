import * as XLSX from "xlsx";

const SalesLoad_UploadContainer = (obProps) => {


    return (
        <div class="uploadContainer">
            <div class="uploadArea">
                <div>
                    <label for="file"><strong>Choose a file</strong><span class="box-dragndrop"> or drag it here</span>.</label>
                </div>
                <div class="fileUpload">
                    <input
                        type="file"
                        onChange={(e) => {
                            // setSheetData(null);
                            const file = e.target.files[0];
                            obProps.OnReadExcel(file);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SalesLoad_UploadContainer;