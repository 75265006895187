import { useEffect, useRef, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import useDBClient from "../_SECURE/_hooks/useDBClient";

const useLoggedUser = async () => {
    const myToken = localStorage.getItem("mgm_auth");
    const { getLocalStorageData } = useLocalStorage();

    const [obClientData, setClientData] = useState(getLocalStorageData());
    const isAuthenticated = useRef(true);
    const isAdmin = useRef(false);
    const isClient = useRef(false);

    // console.warn(obClientData.user.role);

    isAuthenticated.current = myToken ? true : false;

    isAdmin.current = (obClientData?.user?.role === 1);
    isClient.current = (obClientData?.user?.role === 2);

    return { isAuthenticated, isAdmin, isClient, obClientData, myToken };
}

export default useLoggedUser;