import { useEffect } from "react";
import "./WhoWeAre.scss";

const WhoWeAre = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "WHO WE ARE",
			subHeaderText: "Who we are",
			class: "gc_class_whoweare",
			link_active: "whoweareLink",
			headerType: "normal"
		});
	}, [obProps]);

	const styles = {
		bgPart: {
			backgroundImage: `url('../../../img/views/whoweare/bg1.fw.png')`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "100% 100%",
			backgroundSize: "100%"
		},
	};

	return (
		<div className="container displayFlex" style={styles.bgPart}>
			<div className="row align-items-start mt-5 firstPart" >
				<div className="col-md-7 col-sm-12 leftPart p-4" >
					<h3 className="mb-4">WHO WE ARE</h3>
					<p>
						We are more than just an E-commerce Management and Prep Center provider; We are the engine driving your e-commer success story.
					</p>
					<p>
						We are on a mission to empower Amazon/eBay/Website sellers with a reliable and seamless fulfillment experience.<br></br>
						We recognize that your success on the world's largest online marketplaces rely on efficient logistics, and we are here to make that a reality for you.
					</p>
					<p>
						<div className="title">Scalability and Growth:</div>
						We understand that your Amazon/eBay/Website selling business may experience growth or fluctuations.<br></br>
						We are prepared to adapt to your changing needs, whether you're expanding your product catalog, dealing with seasonality, or facing unexpected surges in demand.
					</p>
					<p>
						<div className="title">Your Partner in Success:</div>
						Your success as an e-commerce seller is at the core of what we do.<br></br>
						We take immense pride in being the trusted partner behind your online business, helping you achieve your goals and reach new heights.
					</p>
					<br></br>
					<p>
						Choose Green Market Prep Center as your partner when it comes to fulfillment success.<br></br>
						Experience the peace of mind that comes with efficient, high-level compliant fulfillment, and focus on what you do best – growing your business on the world's largest online marketplaces.
					</p>
				</div>
			</div>

			<div className="row align-items-start mt-5 secondPart ">
				<div className="col-md-4 col-sm-12 part">
					<div className="partInternal ">
						<h4>OUR STAFF</h4>
						<div className="staff">
							<div>
								Our team is composed of seasoned e-commerce and logistics experts who are dedicated to the success of your business. We understand the complexities of selling online, and we leverage our expertise to navigate the challenges for you.
							</div>
							<div>
								Our team of seasoned logistics experts is dedicated to delivering on the promises we make to our clients. We have honed our skills through years of experience in supply chain management, order processing, and inventory management.
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 col-sm-12 part">
					<div className="partInternal ">
						<h4>OUR MISSION</h4>
						<div className="mission">
							<div>
								Our mission is to make e-commerce fulfillment hassle-free and efficient for businesses of all sizes, and we are committed to delivering on this promise,
								growing your business on the world's largest online marketplaces.
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-5 col-sm-12 part">
					<div className="partInternal m-0">
						<h4>OUR PARTNERS</h4>
						<div className="partner">
							<div className="partnerLogo col-5 col-lg-4 col-sm-12">
								<img src="./img/logos/partners/Hedger.fw.png" className=" hedger" />
							</div>
							<div className="partnerText col-7 col-lg-8 col-sm-12">
								<div className="title">
									<a href="http://www.hedgerinternational.com">
										Hedger International - <span className="website">website</span>
									</a>
								</div>
								<div className="text">
									Helps companies from any part of the world to create a logistic plan to register and bring your products to the USA, following the legal requirements to sell and ship your products to the USA.
								</div>
							</div>
						</div>
						<div className="partner">
							<div className="partnerLogo col-5 col-lg-4 col-sm-12">
								<img src="./img/logos/partners/aYellow.png" className=" pontoA" />
							</div>
							<div className="partnerText col-7 col-lg-8 col-sm-12">
								<div className="title">
									<a href="http://www.pontoamulticom.com">
										Ponto A Multicom - <span className="website">website</span>
									</a>
								</div>
								<div className="text">
									Helps your company to generate high-quality marketing material that meets the standards in advertising inside Amazon and Social Networks.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhoWeAre;
