import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './overwrite.scss';
import './sizes.scss';
import './style.scss';
import "./fonts.scss";

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from "redux-thunk";

import App from './components/App';
import myReducers from "./reducers";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const myReduxStore = createStore(
	myReducers,
	{}, //setting up some initial state
	applyMiddleware(reduxThunk) //middleware
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// refetchOnmount: false,
			// refetchOnReconnect: false,
			retry: false
			// staleTime: 5 * 60 * 1000,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={myReduxStore}>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</Provider>
); 