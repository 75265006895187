import SalesChart_FBMFBA from "../Charts/SalesChart_FbmFba";
import SalesChart_Shipment from "../Charts/SalesChart_Shipment";
import SalesChart_Asin from "../Charts/SalesChart_Asin";
import SalesChart_Country from "../Charts/SalesChart_Country";
import SalesChart_WeekDay from "../Charts/SalesChart_WeekDay";
import SalesChart_WeekNumber from "../Charts/SalesChart_WeekDayNumber";
import SalesChart_States from "../Charts/SalesChart_States";

const SalesLoad_ChartContainer = (obProps) => {

    const sheetData = obProps.sheetData;
    // let USDollar = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    // });

    return (
        <div className="salesLoadExcel-ChartContainer">
            <div className="salesLoadExcel-Chart salesLoadExcel-ChartRegularWidth">
                <SalesChart_States chartData={sheetData} />
                <SalesChart_Country chartData={sheetData} />
            </div>
            <div className="salesLoadExcel-Chart salesLoadExcel-ChartRegularWidth">
                <SalesChart_Shipment chartData={sheetData} />
                <SalesChart_Asin chartData={sheetData} />
                <SalesChart_FBMFBA chartData={sheetData} />
            </div>
            <div className="salesLoadExcel-Chart salesLoadExcel-ChartRegularWidth">
                <SalesChart_WeekNumber chartData={sheetData} />
                <SalesChart_WeekDay chartData={sheetData} />
            </div>
        </div>
    );
};

export default SalesLoad_ChartContainer;