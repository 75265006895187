import axios from "axios";

const useDBClient = (myToken) => {

    const loadDBClient = async (clientId) => {
        return await axios.get(`/api/client/${clientId}`, {
            headers: { "x-mgm_token": myToken }
        });
        // .then(function (response) {
        //     console.log("loadDBClient response", response);
        //     if (response.status === 200) {
        //         console.log(response.data);
        //         return response.data;
        //     }
        // })
        // .catch(function (error) {
        //     console.log(error);
        // });
    };

    return { loadDBClient };
}

export default useDBClient;