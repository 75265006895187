import React, { useEffect, useState } from "react";
import "./MarketPlaces.scss";
import GCBreadCrumb from "../../../../components/GCBreadCrumb/GCBreadCrumb";

const MarketPlaces = (obProps) => {
    const thisPageData = {
        title: "MarketPlaces",
        subHeaderText: "MarketPlaces",
        class: "gc_class_secure",
        link_active: "marketPlacesHomeLink",
        headerType: "secure"
    }
    useEffect(_ => {
        obProps.onLoadPage(thisPageData);
    }, [obProps]);

    return (
        <>
            <GCBreadCrumb thisPageName={thisPageData.title} level={3} />
            MarketPlaces Configuration
        </>
    );
};

export default MarketPlaces;