import { useEffect, useState } from "react";
import "./Admin.scss";
import React from "react";
import useLoggedUser from "../hooks/useLoggedUser";


const Admin = (obProps) => {

    //---------- CLIENT DATA
    const { obClientData } = useLoggedUser();

    const [clientName, setClientName] = useState("");
    const [clientLogo, setClientLogo] = useState();


    useEffect(() => {
        let obCurrentData = JSON.parse(localStorage.getItem("mgm_currentData"));
        obCurrentData.client.info = {};
        localStorage.setItem("mgm_currentData", JSON.stringify(obCurrentData));
    }, []);


    return (
        <div className="content ADMIN_MAIN container-fluid Full-Width ">
            <div className="containerBackground containerAdminBackground">
                ADMIN DASHBOARD
            </div>
            <div className="containerBackground containerClientBackground">
                {clientName}
            </div>
            <div className="containerBackground containerClientBackgroundImg"
                style={{
                    "backgroundImage": `url(${clientLogo})`
                }}
            >
                image
            </div>
            {/* {obProps.children} */}
            {React.cloneElement(obProps.children, { onLoadView: () => { }, obClientData: obClientData })}
        </div>
    );
};

export default Admin;