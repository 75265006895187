import { data } from "jquery";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { CHART_OPTIONS } from "../../../../../../ReportConstants";

const SalesChart_Asin = (obProps) => {

    const [dataChart, setDataChart] = useState([
        ["-", "-"],
        ["-", 0],
        ["-", 0]
    ]);

    const optionsChart = {
        ...CHART_OPTIONS,
        title: "ASIN",
    };

    const normalizeChartData = _ => {
        const arSales = obProps?.chartData?.all?.sales || [];
        if (arSales.length) {
            console.warn("normalizeChartData", arSales.length);

            const chartProps = ['asin'];

            const obCounts = arSales.reduce((r, e) => {
                Object.entries(e).forEach(([k, v]) => {
                    if (chartProps.includes(k)) {
                        if (!r[k]) r[k] = {}
                        r[k][v] = (r[k][v] || 0) + 1
                    }
                })
                return r;
            }, {})

            const obValues = obCounts[chartProps[0]];
            let dataToChart = [
                ["-", "-"]
            ];
            Object.keys(obValues).forEach(key => {
                dataToChart.push([key, obValues[key]]);
            });
            setDataChart(dataToChart);
            // console.log(optionsChart.title, dataToChart);

        }
    };

    useEffect(() => {
        normalizeChartData();
    }, [obProps.chartData?.all?.sales]);
    console.log(">>>>>> ", obProps.chartData);

    return (
        <div className="">
            <Chart
                chartType="PieChart"
                width="100%"
                height="250px"
                data={dataChart}
                options={optionsChart}
            />
        </div>
    );

};

export default SalesChart_Asin;