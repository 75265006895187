import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-regular-svg-icons";
import * as IconsSolid from "@fortawesome/free-solid-svg-icons";
import "./SalesLoad_TopOptions.scss";

const SalesLoad_TopOptions = (obProps) => {

    return (
        <div className="SalesLoad_TopOptions container">
            <div className="row container">
                <div className="col-3">

                </div>
                <div className="col-3">
                    <button type="button" onClick={obProps.onClickLoadNewFile} class="btn btn-sm btn-outline-primary"><FontAwesomeIcon icon={IconsSolid.faDownload} />Load a New File</button>
                </div>
                <div className="col-3">
                    <button type="button" onClick={obProps.onSaveData} class="btn btn-sm btn-outline-success"><FontAwesomeIcon icon={Icons.faFloppyDisk} />Save</button>
                </div>
                <div className="col-3">
                    <button onClick={obProps.onPrintPDF} type="button" class="btn btn-sm btn-outline-secondary"><FontAwesomeIcon icon={Icons.faFilePdf} />Genrerate PDF</button>
                </div>
            </div>
        </div>
        // "MENU FILE OPTIONS"
    );
};

export default SalesLoad_TopOptions;