import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardList, { CardTypes } from "../../../components/card/CardList";
import "./AdminHome.scss";
import arAdminActions from "../../../data/adminActions.json";
import axios from "axios";
import useLoggedUser from "../../hooks/useLoggedUser";
import { useQuery } from '@tanstack/react-query';
import AdminDashboardCharts from "../Charts/AdminDashboardCharts";
import ChartCalendar from "../Charts/ChartTypes/ChartCalendar";

const AdminHome = (obProps) => {

	// const [arClients, setClients] = useState([]);
	const { myCookie } = useLoggedUser();

	const history = useHistory();

	const cleanNavigation = _ => {
		let obCurrentData = JSON.parse(localStorage.getItem("mgm_currentData"));
		obCurrentData.client.info = {};
		localStorage.setItem("mgm_currentData", JSON.stringify(obCurrentData));
	};

	useEffect(_ => {
		obProps.onLoadPage({
			title: "ADMIN HOME",
			subHeaderText: "Admin Home",
			class: "gc_class_admin",
			headerType: "adm",
		});
		cleanNavigation();
	}, [obProps]);

	const handleOnClientClick = (cliendData) => {
		console.log("CLICKED", cliendData.clientId);

		let obCurrentData = JSON.parse(localStorage.getItem("mgm_currentData"));
		obCurrentData.client.info = arClients.find(thisClient => thisClient.clientId === cliendData.clientId);
		localStorage.setItem("mgm_currentData", JSON.stringify(obCurrentData));

		history.push(`/secure/client/view`);
	};

	const handleOnAdminActionClick = (actionData) => {
		console.log("ACTION CLICKED", actionData.clientId);
	};

	// Query
	const arClients = useQuery({
		queryKey: ['clients-list'],
		refetchOnWindowFocus: false,
		queryFn: async () => axios.get("/api/clients", {
			headers: { "x-mgm_token": myCookie }
		}),
		select: (response) => {
			if (response.status === 200) {
				console.log("Data status 200:", response.data);
				let arClientsData = response.data;
				arClientsData.forEach(thisClient => {
					thisClient.linkId = thisClient.clientId;
					thisClient.image = `../img/clients/logos/LogoDashboard_${thisClient.clientId}.fw.png`
				});
				return arClientsData;
			} else {
				return [];
			}
		}
	}).data;

	// const arAdminActions2 = useQuery({
	// 	queryKey: ['clients-list'],
	// 	refetchOnWindowFocus: false,
	// 	queryFn: async () => axios.get("data/adminActions.json", {
	// 		headers: { "x-mgm_token": myCookie }
	// 	}),
	// 	select: (response) => {
	// 		console.log("querySelect arAdminActions:", response);
	// 		if (response.status === 200) {
	// 			return response.data;
	// 		} else {
	// 			return [];
	// 		}
	// 	}
	// }).data;

	return (
		<>
			<div className="Full-Width row ">
				<div className="col col-5 align-items-center">
					<div className="Full-Width row justify-content-center">
						<AdminDashboardCharts />
					</div>
					{/* <div className="Full-Width row justify-content-center">
						<div className="sectionTitle">ATTENTION!</div>
						<div className="sectionContent">content</div>
					</div>
					<div className="Full-Width row justify-content-center">
						<div className="sectionTitle">MESSAGES</div>
						<div className="sectionContent">content</div>
					</div> */}
					<div className="Full-Width fw-bold text-left section clients">
						<div className="sectionTitle">URGENCY</div>
						<div className="sectionContent">
							<div className="Content">
								List for urgencies
							</div>
						</div>
					</div>
					<div className="Full-Width fw-bold text-left section clients">
						<div className="sectionTitle">MESSAGES</div>
						<div className="sectionContent">
							<div className="Content">
								List for messages
							</div>
						</div>
					</div>
				</div>
				<div className=" col col-7">
					<div className="Full-Width fw-bold text-left section clients">
						<div className="sectionTitle">CLIENTS</div>
						<div className="sectionContent">
							<CardList onClick={handleOnClientClick} cardType={CardTypes.TINY} arCardList={arClients} />
						</div>
					</div>

					<div className="Full-Width fw-bold text-left section actions">
						<div className="sectionTitle">ACTIONS</div>
						<div className="sectionContent">
							<CardList onClick={handleOnAdminActionClick} cardType={CardTypes.TINY} arCardList={arAdminActions.data} />
						</div>
					</div>

					<div className="Full-Width fw-bold text-left section actions">
						<div className="sectionTitle">EVENTS CALENDAR</div>
						<div className="sectionContent">
							<div className="Content">
								<ChartCalendar obData={{}} />
							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
};

export default AdminHome;
