import { useEffect } from "react";
import "./Home.scss";
// import HomeCarousel from "./HomeCarousel";

const Home = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "Home",
			subHeaderText: "Home",
			class: "gc_class_home",
			link_active: "homeLink",
			headerType: "normal"
		});
	}, [obProps]);

	return (
		<div className="full_height flex-top position-ref ">
			<div className="content HOME modules">
				{/* <div className="Full-Width module module1">
					<HomeCarousel />
				</div> 

				<div className="Full-Width module module2">
					<div className="subtitle Full-Width module1_bg">
					</div>
					<div className="subtitle Full-Width module_txt">
						E-STORE MANAGEMENT
					</div>
				</div>

				<div className="Full-Width module module3">
					<div className="subtitle Full-Width module2_bg">
					</div>
					<div className="subtitle Full-Width module_txt">
						PREP CENTER
					</div>
				</div>
				*/}
			</div>
		</div>
	);
};

export default Home;
