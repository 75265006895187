
export const CHART_OPTIONS = {
    is3D: false,
    legend: { textStyle: { fontName: 'Calibri', fontSize: 10, bold: false }, position: "bottom" },
    backgroundColor: 'transparent',
    animation: {
        startup: true,
        easing: "in",
        duration: 2000,
    },
    tooltip: { ignoreBounds: true },
    chartArea: {
        width: "auto"
    },
    colors: ['#097969', '#5F9EA0', '#A2E1dF', '#2ca02c', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#c7c7c7', '#dbdb8d', '#17becf', '#9edae5']
};

export const AR_WEEK_DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];