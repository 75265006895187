import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { CHART_OPTIONS } from "../../../../../../ReportConstants";

const SalesChart_States = (obProps) => {

    var optionsChart = {
        ...CHART_OPTIONS,
        region: "US",
        displayMode: 'regions',
        resolution: 'provinces',
        title: "Best of 5 States",
        legend: { position: "bottom" },
        colorAxis: { colors: CHART_OPTIONS.colors },
        tooltip: { ignoreBounds: true }
    };

    const [dataChart, setDataChart] = useState([]);

    const normalizeChartData = _ => {
        const arSales = obProps?.chartData?.all?.sales || [];

        if (arSales?.length) {
            const chartProps = ['ship-state'];

            const obCounts = arSales.reduce((r, e) => {
                Object.entries(e).forEach(([k, v]) => {
                    if (chartProps.includes(k)) {
                        if (!r[k]) r[k] = {}
                        r[k][v] = (r[k][v] || 0) + 1
                    }
                })
                return r;
            }, {})

            const obValues = obCounts[chartProps[0]];
            let dataToChart = [
                ["State", "Items Sold"]
            ];

            let dataToChartTemp = [];
            Object.keys(obValues).forEach(key => {
                dataToChartTemp.push([key, obValues[key]]);
            })
            dataToChartTemp = dataToChartTemp.sort(function (a, b) {
                return b[1] - a[1];
            });
            dataToChart = dataToChart.concat(dataToChartTemp.slice(0, 5));

            setDataChart(prev => dataToChart);
        }
    };


    useEffect(_ => {
        normalizeChartData();
    }, [obProps.chartData?.all?.sales]);

    return (
        <>
            <Chart
                chartType="PieChart"
                width="100%"
                height="250px"
                data={dataChart}
                options={optionsChart}
            />
            <div id="SalesChart_States"></div>
        </>

    )
};

export default SalesChart_States;