import { Link } from "react-router-dom";
import obConfig from "../../../../src/data/configuration.json";
import "./Header.scss";


const Header = (obProps) => {

	const viewsConfig = obConfig.VIEWS;

	const handleCollapse = (event) => {
		// event.preventDefault();
		console.log("handleCollapse");
		var nav = document.getElementById("navbarsExampleDefault");
		var btn = document.getElementById("navbarBtn");
		nav.classList.remove("show");
		btn.classList.add("collapsed");
	}

	return (
		<header className="unsecure fixed-top">

			<nav className="navbar navbar-expand-md navbar-light pt-0 pb-0 ">

				<Link className="logo navbar-brand" to="/home">
					<img src="img/logos/MGM-LogoHeader.png" alt="GM Logo" />
				</Link>

				<button
					id="navbarBtn"
					className="navbar-toggler d-lg-none navbar-light"
					type="button"
					data-toggle="collapse"
					data-target="#navbarsExampleDefault"
					aria-controls="navbarsExampleDefault"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div
					className="collapse navbar-collapse"
					id="navbarsExampleDefault"
					data-toggle="collapse"
				>
					<ul className="navbar-nav ">
						{viewsConfig?.WHO_WE_ARE?.display && (
							<li id="whoweareLink" className="nav-item  asButton ">
								<Link className="nav-link " to="/whoweare" onClick={handleCollapse}>
									OUR TEAM
								</Link>
							</li>
						)}
						{viewsConfig?.E_MANAGEMENT?.display && (
							<li id="emanagementLink" className="nav-item asButton">
								<Link className="nav-link " to="/emanagement" onClick={handleCollapse}>
									E-MANAGEMENT
								</Link>
							</li>
						)}
						{viewsConfig?.PREP_CENTER?.display && (
							<li id="prepcenterLink" className="nav-item asButton ">
								<Link className="nav-link " to="/prepfulfillment" onClick={handleCollapse}>
									PREP & FULFILLMENT
								</Link>
							</li>
						)}
						{viewsConfig?.PORTFOLIO?.display && (
							<li id="portfolioLink" className="nav-item asButton">
								<Link className="nav-link " to="/portfolio" onClick={handleCollapse}>
									PORTFOLIO
								</Link>
							</li>
						)}
						{viewsConfig?.CONTACT?.display && (
							<li id="contactLink" className="nav-item asButton">
								<Link className="nav-link " to="/contact" onClick={handleCollapse}>
									CONTACT
								</Link>
							</li>
						)}
						{viewsConfig?.LOGIN?.display && (
							<li id="loginLink" className="nav-item asButton ">
								<Link
									className="nav-link login_logout btn btn-outline-success"
									to="/login"
								>
									Log In / Register
								</Link>
							</li>
						)}
					</ul>
				</div>

			</nav>
		</header>
	);
};

export default Header;
