const useLocalStorage = (obProps) => {

    const getLocalStorageData = () => {
        return JSON.parse(localStorage.getItem("mgm_currentData"));
    };

    const setLocalStorageData = (obData) => {
        return localStorage.setItem("mgm_currentData", JSON.stringify(obData));
    };

    return { getLocalStorageData, setLocalStorageData };
}

export default useLocalStorage;