// import { useEffect, useState } from "react";
// import ProductItems from "../../data/ProductItems.json";
// import ProductList from "../../components/products/ProductList";
import "./Emanagement.scss";

const Emanagement = (obProps) => {

	// const [categoryFilter, setCategoryFilter] = useState(null);
	// const [arProductsList, setArProductsList] = useState(ProductItems.data);
	// const [arFilteredListProducts, setFilteredListProducts] = useState([]);

	// const loadProductList_ASYNC = () => {

	// 	if (categoryFilter && categoryFilter !== "*") {
	// 		// console.log("new category > ", categoryFilter);
	// 		setFilteredListProducts(arProductsList.filter((thisItem) => {
	// 			return (thisItem.category.indexOf(categoryFilter) > -1);
	// 		}));
	// 	} else {
	// 		// console.log("NO category > ", arProductsList);
	// 		return setFilteredListProducts(arProductsList);
	// 	}
	// };

	// useEffect(() => {
	// 	// console.log("categoryFilter HAS CHANGED");
	// 	loadProductList_ASYNC();
	// }, [categoryFilter]);

	// const fnCategoryFilterClickedHandler = (stFilter) => {
	// 	// console.log("fnFilterClickedHandler", stFilter);
	// 	setCategoryFilter(stFilter);
	// };


	obProps.onLoadPage({
		title: "E-Management",
		subHeaderText: "E-Management",
		class: "gc_class_emanagement",
		link_active: "emanagementLink",
		headerType: "normal"
	});

	return (
		<></>
		// <NavBarHouseInfo onFilterClicked={fnCategoryFilterClickedHandler} />
		// <ProductList arProductList={arFilteredListProducts} />
	);

};

export default Emanagement;