import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

const SalesLoad_TableContainer = (obProps) => {

    const sheetData = obProps.sheetData;
    // const MySwal = withReactContent(Swal);

    const buildHTMLSwal = (obData) => {
        var arOrderDataHtml = `<table className='modalTable salesModalTable'>
            <thead>
            <tr>
                <th>Property</th>
                <th>Value</th>
            </tr>
        </thead >`;

        arOrderDataHtml += "<tbody>";
        Object.keys(obData).forEach(key => {
            arOrderDataHtml += "<tr>";
            arOrderDataHtml += "<td class='property'>";
            arOrderDataHtml += (key + "").substring(0, 20);
            arOrderDataHtml += "</td>";
            arOrderDataHtml += "<td class='value'>";
            arOrderDataHtml += (obData[key] + ""); // .substring(0, 50);
            arOrderDataHtml += "</td>";
            arOrderDataHtml += "</tr>";
        });
        arOrderDataHtml += "</tbody></table>";

        return arOrderDataHtml;
    }

    const handleClickRow = (thisRow) => {
        thisRow.preventDefault();
        const orderData = JSON.parse(thisRow.currentTarget.dataset.orderdata);

        Swal.fire({
            title: 'Order ' + orderData["amazon-order-id"].substring(12),
            html: buildHTMLSwal(orderData),
            width: 600,
            showClass: {
                popup: 'animated fadeInDown '
            }
        });
    }

    return (
        <div className=" report-sales-tableContainer">
            <table className="table  report-sales-table">
                <thead>
                    <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">ASIN</th>
                        <th scope="col">Status</th>
                        <th scope="col">State</th>
                        <th scope="col">Purchase Date</th>
                        <th scope="col">FBM/FBA</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total $</th>
                    </tr>
                </thead>
                {(sheetData?.all?.sales?.length) &&
                    <tbody>
                        {
                            (sheetData?.all?.sales).map((d) => (
                                <tr key={d["amazon-order-id"] ? d["amazon-order-id"] + "" + d["asin"] : null}
                                    className={d["item-status"]}
                                    data-orderData={JSON.stringify(d)}
                                    onClick={handleClickRow}
                                >
                                    <td>{d["amazon-order-id"]?.substring(12)}</td>
                                    <td>{d["asin"]}</td>
                                    <td>{d["item-status"]}</td>
                                    <td>{d["ship-state"]}</td>
                                    <td>{d["purchaseDate"]}</td>
                                    <td>{d["fba-fbm"]}</td>
                                    <td>{d["quantity"]}</td>
                                    <td>{d["item-price"]}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                }

            </table>
        </div>
    );
};

export default SalesLoad_TableContainer;