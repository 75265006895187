import { useEffect } from "react";
// import PortfolioList from "../../../components/portfolio/PortfolioList";
// import PortfolioItems from "../../../data/PortfolioItems.json";

import "./Portfolio.scss";

const Portfolio = (obProps) => {

	useEffect(_ => {
		obProps.onLoadPage({
			title: "PORTFOLIO",
			subHeaderText: "Portfolio",
			class: "gc_class_portfolio",
			link_active: "portfolioLink",
			headerType: "normal"
		});
	}, [obProps]);

	// const [categoryFilter, setCategoryFilter] = useState(null);
	// const [arProductsList, setArProductsList] = useState(ProductItems.data);
	// const [arFilteredListPortfolio, setFilteredListPortfolio] = useState([]);


	// const loadProductList_ASYNC = () => {

	// 	if (categoryFilter && categoryFilter !== "*") {
	// 		// console.log("new category > ", categoryFilter);
	// 		setFilteredListProducts(arProductsList.filter((thisItem) => {
	// 			return (thisItem.category.indexOf(categoryFilter) > -1);
	// 		}));
	// 	} else {
	// 		// console.log("NO category > ", arProductsList);
	// 		return setFilteredListProducts(arProductsList);
	// 	}
	// };

	// useEffect(() => {
	// 	// console.log("categoryFilter HAS CHANGED");
	// 	loadProductList_ASYNC();
	// }, [categoryFilter]);

	// const fnCategoryFilterClickedHandler = (stFilter) => {
	// 	// console.log("fnFilterClickedHandler", stFilter);
	// 	setCategoryFilter(stFilter);
	// };


	return (
		<>
			{/* <PortfolioList arPortfolioList={arFilteredListPortfolio} /> */}
		</>
	);

};

export default Portfolio;