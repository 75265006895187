
import { useEffect, useRef, useState } from "react";
import "./SalesLoadExcel.scss";
import * as XLSX from "xlsx";
import moment from 'moment';
import Swal from 'sweetalert2';

import SalesLoad_KeyDataContainer from "./Structure/SalesLoad_KeyDataContainer";
import SalesLoad_ChartContainer from "./Structure/SalesLoad_ChartContainer";
import SalesLoad_TableContainer from "./Structure/SalesLoad_TableContainer";
import SalesLoad_UploadContainer from "./Structure/SalesLoad_UploadContainer";
import SalesLoad_TopOptions from "./Structure/SalesLoad_TopOptions";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import useAmazonSalesHook from "../../hooks/useAmazonSalesHook";

const SalesLoadExcel = (obProps) => {

    moment().format("MM/DD/YYYY");

    // const MySwal = withReactContent(Swal);

    const useAmazonSales = useAmazonSalesHook();

    // const [swalProps, setSwalProps] = useState({});
    const [sheetData, setSheetData] = useState({
        sheets: [{
            name: "",
            sales: [],
            totalSale: 0,
            quantity: 0
        }]
    });
    const refSheetData = useRef({
        sheets: [{
            name: "",
            sales: [],
            totalSale: 0,
            quantity: 0
        }]
    });

    const thisPageData = {
        title: "Sales - LoadExcel",
        subHeaderText: "Admin Reports - Sales",
        class: "gc_class_secure",
        link_active: "reportsHomeLink",
        headerType: "secure"
    }
    useEffect(_ => {
        // obProps.onLoadPage(thisPageData);
    }, [thisPageData]);



    const handleReadExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const sheetNames = wb.SheetNames;
                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve({ sheetNames, data });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((excelData) => {
            let arSheets = [];
            (excelData.sheetNames).forEach((thisSheetName) => {
                let arSheet = {
                    name: thisSheetName,
                    sales: useAmazonSales.normalizeSales(excelData.data)
                }
                arSheets.push(arSheet);
            });

            refSheetData.current = arSheets;

            executeDataKeyFactors();
        });
    };

    const executeDataKeyFactors = _ => {

        // console.info("executeDataKeyFactors", "SHEETS", refSheetData.current);
        let countItems = 0
        let totalSale = 0;
        let countRefund = 0;
        var allSales = [];
        (refSheetData.current).forEach(thisSheet => {
            const arTheSales = thisSheet.sales;
            allSales = allSales.concat(arTheSales);

            arTheSales.forEach(thisSale => {
                if (thisSale["asin"]) {
                    countItems += isNaN(thisSale.quantity) ? 0 : thisSale.quantity;
                    totalSale += isNaN(thisSale["item-price"]) ? 0 : parseFloat(thisSale["item-price"]);
                    countRefund += thisSale["item-status"] === "Cancelled" ? 1 : 0;
                }
            });

        });

        refSheetData.current.all = {
            quantity: countItems,
            totalSale: parseFloat(totalSale).toFixed(2),
            totalCancelled: countRefund,
            sales: allSales
        };
        setSheetData(refSheetData.current);
    }

    const handleLoadNewFile = (oData) => {
        console.log(">>>> handleLoadNewFile");
        setSheetData(null);
    };

    const handleBackToReports = _ => {
        obProps.onBackToMain();
    };

    const handleSaveDataFromExcel = _ => {
        Swal.fire({
            inputLabel: 'Search for a movie. e.g. "La La Land".',
            input: 'text',
            button: {
                text: "Search!",
                closeModal: false,
            },
        });

        // console.log(myValue);
        // .then(name => {
        //     if (!name) throw null;

        //     return fetch(`https://itunes.apple.com/search?term=${name}&entity=movie`);
        // })
        // .then(results => {
        //     return results.json();
        // })
        // .then(json => {
        //     const movie = json.results[0];

        //     if (!movie) {
        //         return MySwal.fire("No movie was found!");
        //     }

        //     const name = movie.trackName;
        //     const imageURL = movie.artworkUrl100;

        //     MySwal.fire({
        //         title: "Top result:",
        //         text: name,
        //         icon: imageURL,
        //     });
        // })
        // .catch(err => {
        //     if (err) {
        //         MySwal.fire("Oh noes!", "The AJAX request failed!", "error");
        //     } else {
        //         MySwal.stopLoading();
        //         MySwal.close();
        //     }
        // });
    };

    return (
        <>
            {/* <Page className="PDFPage"> */}
            <div className="row reportTop">
                <div className="col col-2 reportTitle"><button type="button" onClick={handleBackToReports} className="btn btn-sm btn-outline-warning">{`< BACK TO REPORTS`}</button></div>
                <div className="col col-3 reportTitle">SALES REPORT</div>
                <div className="col col-7 reportOptions">
                    {sheetData?.all?.sales?.length && <SalesLoad_TopOptions
                        onClickLoadNewFile={handleLoadNewFile}
                        onPrintPDF={obProps.onPrintPDF}
                        onSaveData={handleSaveDataFromExcel}
                        sheetData={sheetData} />}
                </div>
            </div>
            {!sheetData?.all?.sales?.length &&
                <SalesLoad_UploadContainer OnReadExcel={handleReadExcel} />
            }
            {
                sheetData?.all?.sales?.length &&
                <div className="report">

                    <div className="report-sales-DataContainer">
                        <div className="col col-sm-12 col-lg-4">
                            <SalesLoad_TableContainer sheetData={sheetData} />
                        </div>
                        <div className="col col-sm-12 col-lg-8">
                            <div className="reportExtraData">
                                <div className="row full-width p-0 m-0">
                                    <SalesLoad_KeyDataContainer sheetData={sheetData} />
                                </div>
                                <div className="row full-width p-0 m-0 ">
                                    <SalesLoad_ChartContainer sheetData={sheetData} />
                                </div>
                            </div >
                        </div>
                    </div >
                </div >
            }
            {/* </Page> */}
        </>
    );
};

export default SalesLoadExcel;
