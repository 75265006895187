import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./structure/header/Header";
import Footer from "./structure/footer/Footer";
import Home from "../Routes/_NORMAL/Home/Home";
import WhoWeAre from "../Routes/_NORMAL/WhoWeAre/WhoWeAre";
import Emanagement from "../Routes/_NORMAL/Emanagement/Emanagement";
import Contact from "../Routes/_NORMAL/Contact/Contact";
import PrepFulfillment from "../Routes/_NORMAL/PrepFulfillment/PrepFulfillment";
import Login from "../Routes/_NORMAL/Login/Login";
import { useEffect } from "react";
import SecureHome from "../Routes/_SECURE/Home/SecureHome";
import AdminHome from "../Routes/_ADMIN/Home/AdminHome";
import Portfolio from "../Routes/_NORMAL/Portfolio/Portfolio";
import HeaderAdmin from "./structure/header/HeaderAdmin";
import KeywordHelper from "../Routes/_ADMIN/Utilities/KeywordHelper";
import Admin from "../Routes/_ADMIN/Admin";
import Secure from "../Routes/_SECURE/Secure";
import ClientDashboard from "../Routes/_SECURE/GenInfo/ClientView/ClientDashboard";
import ClientData from "../Routes/_SECURE/GenInfo/ClientView/ClientData";
import HeaderSecure from "./structure/header/HeaderSecure";
import SubHeader from "./structure/subHeader/SubHeader";
import useLoadPage from "../Routes/hooks/useLoadPage";
import useLoggedUser from "../Routes/hooks/useLoggedUser";
import useLogOut from "../Routes/hooks/useLogOut";
import PrepCenter from "../Routes/_SECURE/GenInfo/PrepCenter/PrepCenter";
import MarketPlaces from "../Routes/_SECURE/GenInfo/MarketPlaces/MarketPlaces";
import Reports from "../Routes/_SECURE/GenInfo/Reports/Reports";
import MrktPlcOverview from "../Routes/_SECURE/MarketPlaceViews/MrktPlcOverview";
import MrktPlcCharts from "../Routes/_SECURE/MarketPlaceViews/MrktPlcCatalog";
import MrktPlcCatalog from "../Routes/_SECURE/MarketPlaceViews/MrktPlcCatalog";
import MrktPlcInventory from "../Routes/_SECURE/MarketPlaceViews/MrktPlcInventory";
import MrktPlcOrders from "../Routes/_SECURE/MarketPlaceViews/MrktPlcOrders";
import MrktPlcReports from "../Routes/_SECURE/MarketPlaceViews/MrktPlcReports";
import MrktPlcDiscounts from "../Routes/_SECURE/MarketPlaceViews/MrktPlcDiscounts";

function App(obPageProperties) {

	const { headerType, updateDocument } = useLoadPage();
	const { isAuthenticated } = useLoggedUser();
	const { handleLogOut } = useLogOut();

	useEffect((obPageProperties) => {
		updateDocument(obPageProperties);
		// console.log("headerType", headerType);
		// console.log("obClientData", obClientData);
	}, [updateDocument, headerType]);


	return (
		<div className="App">
			<BrowserRouter>
				{headerType === "normal" && <Header />}
				{headerType === "adm" && <HeaderAdmin onLogout={handleLogOut} />}
				{headerType === "secure" && <HeaderSecure onLogout={handleLogOut} />}
				{(headerType === "secure" || headerType === "adm") && (<SubHeader />)}
				<div
					id="container"
					className="container-fluid col-sm-12 col-md-12"
				>
					<Switch>
						<Route exact path="/" component={() => (<Home onLoadPage={updateDocument} />)} />
						<Route exact path="/home" component={() => (<Home onLoadPage={updateDocument} />)} />
						<Route
							exact
							path="/whoweare"
							component={() => (
								<WhoWeAre onLoadPage={updateDocument} />
							)}
						/>
						<Route
							exact
							path="/prepfulfillment"
							component={() => (
								<PrepFulfillment onLoadPage={updateDocument} />
							)}
						/>
						<Route
							exact
							path="/emanagement"
							component={() => (
								<Emanagement onLoadPage={updateDocument} />
							)}
						/>
						<Route
							exact
							path="/portfolio"
							component={() => (
								<Portfolio onLoadPage={updateDocument} />
							)}
						/>
						<Route
							exact
							path="/contact"
							component={() => (
								<Contact onLoadPage={updateDocument} />
							)}
						/>
						<Route
							exact
							path="/login"
							component={() => (
								<Login onLoadPage={updateDocument} />
							)}
						/>

						{/* =========== SECURE ========== */}
						<Route
							exact
							path="/secure"
							component={() => (
								<Secure>
									{/* <SecureHome onLoadPage={updateDocument} /> */}
									<ClientDashboard onLoadPage={updateDocument} clientId={1} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/secure/home"
							component={() => (
								<SecureHome onLoadPage={updateDocument} />
							)}
						/>

						{/* =========== ADMIN ========== */}
						<Route
							exact
							path="/admin"
							component={() => (
								<Secure>
									<AdminHome onLoadPage={updateDocument} clientId={1} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/admin/home"
							component={() => {
								if (isAuthenticated) {
									return (
										<Admin onLogout={handleLogOut}>
											<AdminHome onLoadPage={updateDocument} />
										</Admin>
									);
								} else { return (<Login onLoadPage={updateDocument} />); }
							}}
						/>
						{isAuthenticated && (
							<Route
								exact
								path="/admin/utils/keywords"
								component={() => (
									<Admin>
										<KeywordHelper onLoadPage={updateDocument} />
									</Admin>
								)}
							/>
						)}

						<Route
							exact
							path="/secure/client/prepCenter"
							component={() => (
								<Secure>
									<PrepCenter onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/secure/client/marketPlaces"
							component={() => (
								<Secure>
									<MarketPlaces onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/secure/client/reports"
							component={() => (
								<Secure>
									<Reports onLoadPage={updateDocument} />
								</Secure>
							)}
						/>


						<Route
							exact
							path="/secure/client/view"
							component={() => (
								<Secure>
									<ClientDashboard onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/secure/client/view/clientData"
							component={() => (
								<Secure>
									<ClientData onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route
							exact
							path="/secure/utilities/reports"
							component={() => (
								<Secure>
									<Reports onLoadPage={updateDocument} />
								</Secure>
							)}
						/>

						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/ovrvw"
							component={() => (
								<Secure>
									<MrktPlcOverview onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/catal"
							component={() => (
								<Secure>
									<MrktPlcCatalog onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/invtr"
							component={() => (
								<Secure>
									<MrktPlcInventory onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/order"
							component={() => (
								<Secure>
									<MrktPlcOrders onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/reprt"
							component={() => (
								<Secure>
									<MrktPlcReports onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/disct"
							component={() => (
								<Secure>
									<MrktPlcDiscounts onLoadPage={updateDocument} />
								</Secure>
							)}
						/>
						<Route exact
							path="/secure/client/mktplace/:marketPlaceId/advert"
							component={() => (
								<Secure>
									<MrktPlcDiscounts onLoadPage={updateDocument} />
								</Secure>
							)}
						/>

					</Switch>
				</div>
				{headerType === "normal" && (<Footer />)}
			</BrowserRouter>
		</div>
	);
}

export default App;
